@font-face {
    font-family: 'impact';
    src: url('/public/asset/fonts/impact.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
*{
    /* font-family: 'Chillax', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif; */
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
a{
    color: #333;
    text-decoration: none;
}
ul{
    margin: 0px;
    padding: 0px;
}
li,
p,
h3,
h1{
    margin: 0px;
    padding: 0px;
    list-style: none;
    /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
li,
div,
p{
    font-weight: 500;
    /* font-family: 'Roboto', sans-serif; */
}
/* ************ ALERT ************* */
.form-input-alert{
    width: 100%;
    height: 20px;
    /* margin: 12px 0px; */
    background-color: transparent;
}
.form-input-alert span{
    font-size: 13px;
}
/* ************** APP MAIN PRELOADER ********** */
.preloader-conatiner{
    top: 0px;
    left: 0px;
    z-index: 40;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    opacity: 0;
    visibility: hidden;
    align-items: center;
    transition: all 2s ease;
    justify-content: center;
    background-color: #fff;
}
.preloader-conatiner.active{
    opacity: 1;
    visibility: visible;
}
.preloader-conatiner .inner-preloader .title-header{
    top: -50px;
    opacity: 0;
    visibility: hidden;
    margin: 20px 0px;
    position: relative;
    transition: all 0.7s ease;
}
.preloader-conatiner.active .inner-preloader .title-header{
    top: 0px;
    opacity: 1;
    visibility: visible;
}
.preloader-conatiner .inner-preloader .title-header h3,
.preloader-conatiner .inner-preloader .title-header h3 span{
    font-size: 60px;
    letter-spacing: 5px;
    font-weight: bold;
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
.preloader-conatiner .inner-preloader{
    width: 60%;
    padding: 100px 0px;
    overflow: hidden;
    text-align: center;
}
.preloader-conatiner .inner-preloader h3{
    color: #333;
}
.preloader-conatiner .inner-preloader h3 span{
    color: #FDB813;
}
.preloader-conatiner .inner-preloader .line{
    right: -100%;
    width: 500px;
    height: 5px;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    position: relative;
    border-radius: 10px;
    background-color: #e1e1e1;
    transition: all 0.5s ease;
}
.preloader-conatiner .inner-preloader .line .bar{
    width: 60%;
    height: 5px;
    border-radius: 10px;
    background-color: #FDB813;
    animation: bar 5s steps(5) both;
}
.preloader-conatiner.active .inner-preloader .line{
    right: 0px;
    opacity: 1;
    visibility: visible;
}
/* ************* dark mode ************ */
.portfolio-container.dark .preloader-conatiner{
    background-color: rgb(14, 14, 14);
}
.portfolio-container.dark .preloader-conatiner .inner-preloader h3{
    color: #fff;
}
.portfolio-container.dark .preloader-conatiner .inner-preloader h3 span{
    color: #FDB813;
}
.portfolio-container.dark .preloader-conatiner .inner-preloader .line{
    background-color: #333;
}
.portfolio-container.dark .preloader-conatiner .inner-preloader .line .bar{
    background-color: #FDB813;
}
@keyframes bar {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
@media only screen and (max-width: 567px){
    .preloader-conatiner .inner-preloader .title-header h3,
    .preloader-conatiner .inner-preloader .title-header h3 span{
        font-size: 40px;
    }
    .preloader-conatiner .inner-preloader .line{
        width: 80%;
    }
    .preloader-conatiner .inner-preloader{
        width: 95%;
    }
}
@media only screen and (max-width: 398px){
    .preloader-conatiner .inner-preloader .title-header h3,
    .preloader-conatiner .inner-preloader .title-header h3 span{
        font-size: 25px;
    }
}
/* ************ ACTION PRELOADER SECTION ********* */
.content-preloader {
    top: 0px;
    left: 0px;
    z-index: 40;
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.702);
}
.content-preloader .loading{
    text-align: center;
}
.content-preloader .loading h3{
    color: #fff;
    font-size: 18px;
    margin: 10px 0px;
}
.content-preloader .loading img{
    width: 100px;
    height: 100px;
}
@media only screen and (max-width: 767px){
    .content-preloader .loading img{
        width: 70px;
        height: 70px;
    }
    .content-preloader .loading h3{
        font-size: 14px;
    }
}
@media only screen and (max-width: 567px){
    .content-preloader .loading img{
        width: 40px;
        height: 40px;
    }
    .content-preloader .loading h3{
        font-size: 14px;
    }
}
/* ********* APP SECTION ************ */
.portfolio-container{
    width: 100%;
    margin: auto;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}
/* ********* APP SECTION ************ */
.portfolio-container.dark{
    background-color: rgb(14, 14, 14);
}
/* ************ TOP NAVIGATION *********** */
.main-navigation{
    height: 100px;
}
.top-navigation-container{
    z-index: 30;
    width: 100%;
    height: 100px;
    position: fixed;
    background-color: #fff;
    border-bottom: 1px solid #ededed;
}
.top-navigation-container .inner-navigation{
    width: 70%;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.top-navigation-container .inner-navigation .navigation-middle{
    width: 60%;
}
.top-navigation-container .inner-navigation .navigation-middle ul{
    display: flex;
    padding: 0px;
    margin: 0px;
    align-items: center;
    justify-content: space-between;
}
.top-navigation-container .inner-navigation .navigation-middle ul li{
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
    text-transform: uppercase;
}
.top-navigation-container .inner-navigation .navigation-middle ul li:hover{
    color: #FDB813;
}
.top-navigation-container .inner-navigation .navigation-right .icon{
    color: #333;
    cursor: pointer;
    font-size: 30px;
    transition: all 0.3s ease;
}
.top-navigation-container .inner-navigation .navigation-right .icon.moon{
    right: 30px;
    position: relative;
}
.top-navigation-container .inner-navigation .navigation-right .icon:hover,
.top-navigation-container .inner-navigation .navigation-right .icon.moon.active{
    color: #FDB813;
}
.top-navigation-container .inner-navigation .navigation-left .title{
    font-size: 30px;
    font-weight: 600;
    font-family: impact;
    letter-spacing: 3px;
    text-transform: uppercase;
}
.top-navigation-container .inner-navigation .navigation-left .title span{
    color: #FDB813;
    font-family: impact;
    letter-spacing: 3px;
}
/* ************* dark mode *************** */
.portfolio-container.dark .top-navigation-container .inner-navigation .icon,
.portfolio-container.dark .top-navigation-container .inner-navigation{
    color: #ccc;
}
.portfolio-container.dark .top-navigation-container{
    background-color: #151515;
    border-bottom: 1px solid #212121;
 }
 .portfolio-container.dark .top-navigation-container .inner-navigation .navigation-left .title{
    color: #ccc;
 }
.portfolio-container.dark .top-navigation-container .inner-navigation .icon.moon,
.portfolio-container.dark .top-navigation-container .inner-navigation .icon:hover{
    color: #FDB813;
}
.portfolio-container.dark  .top-navigation-container .inner-navigation .navigation-middle ul li a{
    color: #ccc;
}
@media only screen and (max-width: 1400px){
    .top-navigation-container .inner-navigation{
        width: 90%;
    }
}
@media only screen and (max-width: 1200px){
    .top-navigation-container .inner-navigation{
        width: 95%;
    }
}
@media only screen and (max-width: 992px){
    .top-navigation-container .inner-navigation .navigation-middle{
        display: none;
    }
}
@media only screen and (max-width: 567px){
    .main-navigation,
    .top-navigation-container,
    .top-navigation-container .inner-navigation{
        height: 70px;
    }
    .top-navigation-container .inner-navigation .navigation-left .title{
        font-size: 20px;
    }
}
/* ************* SIDE NAVIGATION SECTION ************* */
.side-navigation-container{
    width: 100%;
    height: 100vh;
}
.side-navigation-container .table-content-container{
    top: 0px;
    left: 0px;
    z-index: 30;
    width: 100%;
    height: 100vh;
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.654);
}
.side-navigation-container .side-navigation-body{
    z-index: 30;
    right: 0px;
    width: 450px;
    height: 100vh;
    position: fixed;
    transform: translate(100%);
    background-color: #fff;
    transition: all 0.2s ease;
}
.side-navigation-container.active .dark-skin{
    display: block;
}
.side-navigation-container.active .side-navigation-body{
    transform: translate(0px);
}
.side-navigation-container .side-navigation-body .title-header{
    display: flex;
    height: 100px;
    padding: 0px 20px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;
}
.side-navigation-container .side-navigation-body .title-header .left h3{
    color: #333;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}
.side-navigation-container .side-navigation-body .title-header .left h3 span{
    color: #FDB813;
}
.side-navigation-container .side-navigation-body .title-header .right .icon{
    color: #333;
    font-size: 22px;
    cursor: pointer;
    margin-left: 30px;
    transition: all 0.3s ease;
}
.side-navigation-container .side-navigation-body .title-header .right .icon:hover,
.side-navigation-container .side-navigation-body .title-header .right .icon.moon.active{
    color: #FDB813;
}
.side-navigation-container .side-navigation-body .side-navigation-links{
    border-bottom: 1px solid #ccc;
}
.side-navigation-container .side-navigation-body .side-navigation-links ul{
    padding: 0px;
    margin: 20px 0px;
}
.side-navigation-container .side-navigation-body .side-navigation-links ul li{
    cursor: pointer;
    padding: 10px 10px; 
    transition: all 0.3s ease;
}
.side-navigation-container .side-navigation-body .side-navigation-links ul li:hover{
    color: #FDB813;
    font-weight: bold;
    /* background-color: #f6f6f6; */
}
.side-navigation-container .side-navigation-body .contact-us{
    padding: 30px 10px;
}
.side-navigation-container .side-navigation-body .side-nav-content{
    height: 90vh;
    overflow-Y: auto;
    padding: 0px 10px;
}
.side-navigation-container .side-navigation-body .side-nav-content::-webkit-scrollbar{
    width: 0px;
    background: transparent;
}
.side-navigation-container .side-navigation-body .side-nav-content::-webkit-scrollbar-thumb {
    background: transparent;
}
.side-navigation-container .side-navigation-body .contact-us .title h3{
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0px;
    text-transform: uppercase;
}
.side-navigation-container .side-navigation-body .contact-us .form-group{
    margin: 20px 0px;
}
.side-navigation-container .side-navigation-body .contact-us input,
.side-navigation-container .side-navigation-body .contact-us textarea{
    outline: none;
    box-shadow: none;
    color: #555;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 10px;
    border-radius: 0px;
    border: 2px solid #ccc;

}
.side-navigation-container .side-navigation-body .contact-us input:focus,
.side-navigation-container .side-navigation-body .contact-us textarea:focus{
    outline: none;
    box-shadow: none;
    border: 2px solid #FDB813;
}
.side-navigation-container .side-navigation-body .contact-us input::placeholder,
.side-navigation-container .side-navigation-body .contact-us textarea::placeholder{
    color: #a1a1a1;
}
.side-navigation-container .side-navigation-body .contact-us .form-button button{
    border: none;
    font-weight: bold;
    padding: 20px 30px;
    border: 3px solid #FDB813;
    background-color: #FDB813;
}
.side-navigation-container .side-navigation-body .contact-us .form-button button:hover{
    background-color: #fdb71370;
    border: 3px solid #FDB813;
}
.side-navigation-container .side-navigation-body .contact-us .form-button .icon{
    margin-left: 10px;
}
/* ************** dark mode ************* */
.portfolio-container.dark .side-navigation-container .dark-skin{
    background-color: rgba(0, 0, 0, 0.725);
}
.portfolio-container.dark .side-navigation-container .side-navigation-body{
    background-color: #151515;
    border-left: 1px solid #212121;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .title-header{
    background-color: #101010;
    border-bottom: 1px solid #212121;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .title-header .left h3{
    color: #ccc;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .title-header .left h3 span{
    color: #FDB813;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .title-header .right .icon{
    color: #ccc;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .side-navigation-links{
    border-bottom: 1px solid #212121;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .side-navigation-links ul li,
.portfolio-container.dark .side-navigation-container .side-navigation-body .side-navigation-links ul li a{
    color: #b7b7b7;
}
.portfolio-container.dark  .side-navigation-container .side-navigation-body .side-navigation-links ul li:hover{
    background-color: #181818;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .title-header .right .icon:hover,
.portfolio-container.dark .side-navigation-container .side-navigation-body .title-header .right .icon.moon.active{
    color: #FDB813;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .contact-us .title h3{
    color: #ccc;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .contact-us input,
.portfolio-container.dark .side-navigation-container .side-navigation-body .contact-us textarea{
    color: #949494;
    border: 2px solid #2b2b2b;
    background-color: #151515;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .contact-us input::placeholder,
.portfolio-container.dark .side-navigation-container .side-navigation-body .contact-us textarea::placeholder{
    color: #505050;
}
.portfolio-container.dark .side-navigation-container .side-navigation-body .contact-us .form-button button:hover{
    color: #fff;
}
@media only screen and (max-width: 1400px){
    .side-navigation-container .side-navigation-body .side-nav-content{
        height: 80vh;
    }
}
@media only screen and (max-width: 576px){
    .side-navigation-container .side-navigation-body{
        width: 90%;
    }
    .side-navigation-container .side-navigation-body .title-header{
        height: 70px;
    }
    .side-navigation-container .side-navigation-body .contact-us .form-button button{
        width: 100%;
    }
}

/* ************ BANNER SECTION ************** */
.banner-container{
    width: 70%;
    margin: 0 auto;
    padding: 200px 0px;
}
.banner-container .content-left {
    width: 95%;
}
.banner-container .content-left .title-header h3{
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
    font-size: 60px;
    letter-spacing: 3px;
    font-weight: bold;
}
.banner-container .content-left .title-header h3 span{
    color: #FDB813;
    letter-spacing: 3px;
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
.banner-container .content-left .body{
    font-size: 18px;
    margin: 50px 0px;
    color: #333;
    text-align: justify;
}

.banner-container .content-left .button{
    margin: 50px 0px;
}
.banner-container .content-left .button button{
    border: none;
    cursor: pointer;
    font-size: 18px;
    padding: 20px 40px;
    transition: all 0.3s ease;
    background-color: #FDB813;
    border: 3px solid transparent;
}
.banner-container .content-left .button button .icon{
    margin: 0px 10px;
}
.banner-container .content-left .button button:hover{
    background-color: #fdb71370;
    border: 3px solid #FDB813;
}
.banner-container .content-right .image{
    width: 90%;
    margin: 0 auto;
}
.banner-container .content-right img{
    width: 100%;
    user-select: none;
    border-radius: 100px;
    border-top: 10px solid #333;
    border-bottom: 10px solid #333;
}
/* ************ dark mode ********** */
.portfolio-container.dark .banner-container .content-left .body{
    color: #ccc;
}
.portfolio-container.dark .banner-container .content-left .title-header h3{
    color: #fff;
}
.portfolio-container.dark .banner-container .content-right img{
    border-top: 10px solid #9d6e00;
    border-bottom: 10px solid #9d6e00;
}
.portfolio-container.dark .banner-container .content-left .button button{
    color: #333;
}
.portfolio-container.dark .banner-container .content-left .button button:hover{
    color: #fff;
}
@media only screen and (max-width: 1400px){
    .banner-container{
        width: 90%;
    }
}
@media only screen and (max-width: 1200px){
    .banner-container .content-right{
        margin-top: 100px;
        display: none;
    }
    .banner-container .content-right .image{
        width: 70%;
    }
}
@media only screen and (max-width: 767px){
    .banner-container .content-right{
        margin-top: 50px;
    }
    .banner-container .content-right .image{
        width: 80%;
    }
}
@media only screen and (max-width: 567px){
    .banner-container{
        width: 95%;
        padding: 50px 0px;
    }
    .banner-container .content-right .image{
        width: 100%;
    }
    .banner-container .content-right img{
        border-radius: 0px;
        border-top: 10px solid #333;
        border-bottom: 10px solid #333;
    }
    .banner-container .content-left .title-header h3{
        font-size: 30px;
        text-align: center;
    }
    .banner-container .content-left{
        width: 100%;
    }
    .banner-container .content-left .button{
        width: 100%;
        text-align: center;
    }
    .banner-container .content-left .button button{
        width: 90%;
    }
    .banner-container .content-left .body{
        font-size: 14px;
        line-height: 25px;
        margin: 20px 0px;
    }
}
@media only screen and (max-width: 398px){
    .banner-container .content-left .title-header h3{
        font-size: 25px;
        text-align: center;
    }
    .banner-container .content-left .body{
        font-size: 14px;
    }
}
/* ************ SERVICES SECTION ************ */
.services-container{
    background-color: #f8f8f8;
}
.services-container .inner-services{
    width: 70%;
    margin: 0 auto;
}
.services-container .title-header{
    text-align: center;
    padding: 50px 0px;
}
.services-container .title-header .title{
    font-weight: 600;
    font-size: 20px;
    margin: 30px 0px;
}
.services-container .title-header h1{
    font-weight: bolder;
    font-size: 50px;
    letter-spacing: 5px;
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
.services-container .services-body{
    padding: 50px 0px;
}
.services-container .services-body .content-item{
    z-index: 20;
    width: 100%;
    height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 10px;
    text-align: center;
    margin-bottom: 50px;
    border: 5px solid #fff;
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(233, 233, 233, 0.772);
}
.services-container .services-body .content-item .title h3{
    font-size: 30px;
    margin: 20px 0px;
    font-weight: bold;
    font-family: impact;
    word-spacing: 5px;
    letter-spacing: 5px;
    text-transform: uppercase;
}
.services-container .services-body .content-item .body{
    font-size: 15px;
    font-weight: 300;
    text-align: justify;
}
/* **************** dark mode ************* */
.portfolio-container.dark .services-container{
    background-color: rgb(7, 7, 7);
}
.portfolio-container.dark .services-container .title-header .title{
    color: #FDB813;
}
.portfolio-container.dark .services-container .title-header h1{
    color: #fff;
}
.portfolio-container.dark .services-container .services-body .content-item{
    box-shadow: none;
    border: 5px solid #323232;
    background-color: #181818;
}
.portfolio-container.dark .services-container .services-body .content-item .title h3{
   color: #FDB813;
}
.portfolio-container.dark .services-container .services-body .content-item .body{
    color: #ccc;
}
@media only screen and (max-width: 1400px){
    .services-container .inner-services{
        width: 90%;
    }
}
@media only screen and (max-width: 567px){
    .services-container .inner-services{
        width: 95%;
    }
    .services-container .title-header h1{
        font-size: 40px;
    }
    .services-container .title-header{
        padding: 0px 0px;
        margin-bottom: 50px;
    }
    .services-container .services-body .content-item .body{
        font-size: 14px;
    }
    .services-container .services-body{
        padding: 0px 0px;
    }
    .services-container .services-body .content-item .title h3{
        font-size: 30px;
    }
}
/* ************* ABOUT SECTION ************** */
.about-us-container{
    padding: 100px 0px;
}
.about-us-container .title-header {
    margin: 20px 0px;
}
.about-us-container .title-header h3{
    font-size: 20px;
    font-weight: 600;
}
.about-us-container .inner-about-us{
    width: 70%;
    margin: 0 auto;
}
.about-us-container .inner-about-us .title-header h3{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}
.about-us-container .inner-about-us .content-left .title h1,
.about-us-container .inner-about-us .content-left .title h1 span{
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 5px;
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
.about-us-container .inner-about-us .content-left .title h1 span{
    color:  #FDB813;
}
.about-us-container .inner-about-us .content-left .body{
    font-size: 20px;
    font-weight: 300;
    margin: 30px 0px;
    text-align: justify;
    background-color: #fff;
}
.about-us-container .inner-about-us .content-left .button{
    margin: 50px 0px;
}
.about-us-container .inner-about-us .content-left .button button{
    border: none;
    cursor: pointer;
    font-size: 18px;
    padding: 20px 40px;
    transition: all 0.3s ease;
    background-color: #FDB813;
    border: 3px solid transparent;
}
.about-us-container .inner-about-us .content-left .button button .icon{
    margin: 0px 10px;
}
.about-us-container .inner-about-us .content-left .button button:hover{
    background-color: #fdb71370;
    border: 3px solid #FDB813;
}
.about-us-container .inner-about-us .content-right{
    width: 70%;
    z-index: 10;
    margin-left: 30%;
    position: relative;
}
.about-us-container .inner-about-us .content-right::after{
    top: 0px;
    right: 0px;
    content: " ";
    width: 90%;
    height: 60%;
    z-index: 5;
    position: absolute;
    background-color: #FDB813;
}
.about-us-container .inner-about-us .content-right .image{
    z-index: 10;
    position: relative;
    padding: 20px 20px 0px 0px;
}
.about-us-container .inner-about-us .content-right .image img{
    width: 100%;
    user-select: none;
}
.about-us-container .inner-about-us .content-right .bottom-content{
    z-index: 10;
    bottom: 0px;
    width: 100%;
    position: absolute;
}
.about-us-container .inner-about-us .content-right .bottom-content ul{
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 20px;
    align-items: center;
    background-color: #333333be;
}
.about-us-container .inner-about-us .content-right .bottom-content ul li{
    color: #fff;
}
.about-us-container .inner-about-us .content-right .bottom-content ul li .icon{
    padding: 10px;
    font-size: 50px;
    margin-right: 10px;
    border-radius: 20px;
    background-color: #535252;
}
.about-us-container .inner-about-us .content-right .bottom-content ul li h3{
    font-size: 25px;
    font-weight: 600;
}
.about-us-container .inner-about-us .content-right .bottom-content ul li p{
    font-size: 16px;
    line-height: 20px;
}
/* *********** dark mode ************ */
.portfolio-container.dark .about-us-container .title-header h3{
    color: #FDB813;
}
.portfolio-container.dark .about-us-container .inner-about-us .content-left .title h1{
    color: #fff;
}
.portfolio-container.dark .about-us-container .inner-about-us .content-left .body{
    color: #ccc;
    background-color: transparent;
}
.portfolio-container.dark .about-us-container .inner-about-us .content-right .bottom-content ul li p{
    color: #ccc;
}
.portfolio-container.dark .about-us-container .inner-about-us .content-left .button button:hover{
    color: #fff;
}
@media only screen and (max-width: 1400px){
    .about-us-container .inner-about-us{
        width: 90%;
    }
}
@media only screen and (max-width: 1200px){
    .about-us-container .inner-about-us .content-right{
        width: 70%;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}
@media only screen and (max-width: 992px){
    .about-us-container .inner-about-us .content-right{
        width: 80%;
    }
}
@media only screen and (max-width: 767px){
    .about-us-container .inner-about-us .content-right{
        width: 100%;
    }
    .about-us-container .inner-about-us .content-right .image{
        padding: 10px 10px 0px 0px;
    }
}
@media only screen and (max-width: 567px){
    .about-us-container .title-header h3{
        text-align: center;
    }
    .about-us-container .inner-about-us{
        width: 95%;
    }
    .about-us-container .inner-about-us .content-left .title h1,
    .about-us-container .inner-about-us .content-left .title h1 span{
        font-size: 35px;
        text-align: center;
    }
    .about-us-container .inner-about-us .content-left .body{
        font-size: 14px;
    }
    .about-us-container .inner-about-us .content-left .button button{
        width: 100%;
    }
    .about-us-container .inner-about-us .content-right .bottom-content ul li .icon{
        font-size: 30px;
    }
    .about-us-container .inner-about-us .content-right .bottom-content ul li h3{
        font-size: 16px;
        font-weight: 600;
    }
    .about-us-container .inner-about-us .content-right .bottom-content ul li p{
        font-size: 14px;
    }
}
/* ************ GET IN TOUCH SECTION ************ */
.get-in-touch-container{
    padding: 100px 0px;
    background-color: #f8f8f8;
}
.get-in-touch-container .inner-get-in-touch{
    width: 70%;
    margin: 0 auto;
}
.get-in-touch-container .inner-get-in-touch .content-left .title-header h1,
.get-in-touch-container .inner-get-in-touch .content-left .title-header h1 span{
    color: #333;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 5px;
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
.get-in-touch-container .inner-get-in-touch .content-left .title-header h1 span{
    color: #FDB813;
    font-size: 120px;
}
.get-in-touch-container .inner-get-in-touch .content-left .body{
    font-size: 18px;
    font-weight: 300;
    margin: 30px 0px;
    text-align: justify;
}
.get-in-touch-container .inner-get-in-touch .content-left .bottom-content-item{
    padding: 20px 0px;
    background-color: #fff;
}
.get-in-touch-container .inner-get-in-touch .content-left .bottom-content-item ul li{
    margin: 20px 0px;
    text-align: center;
}
.get-in-touch-container .inner-get-in-touch .content-left .bottom-content-item ul li .icon{
    font-size: 60px;
}
.get-in-touch-container .inner-get-in-touch .content-left .bottom-content-item ul li.counter{
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 5px;
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
.get-in-touch-container .inner-get-in-touch .content-left .bottom-content-item ul li.description{
    font-size: 20px;
}
.get-in-touch-container .inner-get-in-touch .content-right{
    padding: 50px 50px;
    background-color: #fff;
}
.get-in-touch-container .inner-get-in-touch .content-right .title-header{
    margin: 20px 0px;
}
.get-in-touch-container .inner-get-in-touch .content-right .title-header h3{
    font-size: 50px;
    font-weight: bold;
}
.get-in-touch-container .inner-get-in-touch .content-right .title-header .description{
    font-size: 18px;
    font-weight: 300;
    margin: 20px 0px;
    text-align: justify;
}
.get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group{
    margin: 15px 0px;
}
.get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group input,
.get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group textarea{
    font-size: 20px;
    padding: 15px 10px;
    border-radius: 0px;
    border: 2px solid #ccc;
}
.get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group input:focus,
.get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group textarea:focus{
    outline: none;
    box-shadow: none;
    border: 2px solid #FDB813;
}
.get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group input::placeholder,
.get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group textarea::placeholder{
    color: #ccc;
    font-size: 18px;
}
.get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-button button{
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    padding: 20px 40px;
    transition: all 0.3s ease;
    background-color: #FDB813;
    border: 3px solid transparent;
}
.get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-button button:hover{
    background-color: #fdb71370;
    border: 3px solid #FDB813;
}
.get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-button button .icon{
    margin: 0px 10px;
}
/* ************* dark mode ************** */
.portfolio-container.dark .get-in-touch-container{
    background-color: rgb(7, 7, 7);
}
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-left .title-header h1{
    color: #fff;
}
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-left .body{
    color: #9b9b9b;
}
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-left .bottom-content-item{
    background-color: #151515;
}
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-left .bottom-content-item ul li{
    color: #6a6a6a;
}
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-right{
    background-color: #0e0e0e;
    border: 1px solid #191919;
}
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-right .title-header h3{
   color: #fff;
}
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-right .title-header .description{
    color: #6a6a6a;
}
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group input,
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group textarea{
    color: #ccc;
    border: 2px solid #202020;
    background-color: #151515;
}
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group input::placeholder,
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group textarea::placeholder{
    color: #3a3a3a;
}
.portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-button button:hover{
    color: #fff;
}
@media only screen and (max-width: 1400px){
    .get-in-touch-container .inner-get-in-touch{
        width: 90%;
    }
}
@media only screen and (max-width: 1200px){
    .get-in-touch-container .inner-get-in-touch .content-right,
    .get-in-touch-container .inner-get-in-touch .content-left{
        margin-bottom: 50px;
    }
}
@media only screen and (max-width: 767px){
    .get-in-touch-container .inner-get-in-touch .content-right{
        padding: 50px 20px;
    }
    .get-in-touch-container .inner-get-in-touch .content-left .bottom-content-item{
        margin: 30px 0px;
    }
}
@media only screen and (max-width: 567px){
    .get-in-touch-container .inner-get-in-touch{
        width: 95%;
    }
    .get-in-touch-container .inner-get-in-touch .content-left,
    .get-in-touch-container .inner-get-in-touch .content-right{
        padding: 0px 0px;
    }
    .portfolio-container.dark .get-in-touch-container .inner-get-in-touch .content-right{
        border: none;
        border-top: 1px solid #191919;
        border-bottom: 1px solid #191919;
    }
    .get-in-touch-container .inner-get-in-touch .content-left .title-header h1{
        font-size: 30px;
        text-align: center;
    }
    .get-in-touch-container .inner-get-in-touch .content-left .title-header h1 span{
        font-size: 70px;
    }
    .get-in-touch-container .inner-get-in-touch .content-left .body{
        font-size: 14px;
    }
    .get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-button button{
        width: 100%;
    }
    .get-in-touch-container .inner-get-in-touch .content-right .title-header h3{
        font-size: 30px;
        text-align: center;
    }
    .get-in-touch-container .inner-get-in-touch .content-right .title-header .description{
        font-size: 14px;
    }
    .get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group input,
    .get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group textarea{
        font-size: 18px;
    }
    .get-in-touch-container .inner-get-in-touch .content-left .bottom-content-item ul li.description{
        font-size: 16px;
    }
    .get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group input::placeholder,
    .get-in-touch-container .inner-get-in-touch .content-right .contact-form .form-group textarea::placeholder{
        font-size: 14px;
    }
}
/* ************** SKILLS SECTION  *************/
.skills-container{
    padding: 100px 0px;
}
.skills-container .inner-skills{
    width: 70%;
    margin: 0 auto;
}
.skills-container .inner-skills .title-header{
    margin: 20px 0px;
}
.skills-container .inner-skills .title-header h3{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.skills-container .inner-skills .title-header .title h1{
    font-size: 60px;
    margin: 20px 0px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 5px;
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
.skills-container .inner-skills  .content .content-item{
    display: flex;
    margin: 30px 0px;
}
.skills-container .inner-skills  .content .content-item .inner-content-item{
    width: 100%;
    position: relative;
    padding: 10px 10px;
    align-items: center;
    border: 1px solid #ededed;
}
.skills-container .inner-skills  .content .content-item .top{
    display: flex;
    align-items: center;
}
.skills-container .inner-skills  .content .content-item .title{
    margin-left: 20px;
}
.skills-container .inner-skills  .content .content-item .title  h3{
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
}
.skills-container .inner-skills  .content .content-item .description{
    font-size: 16px;
    font-weight: 300;
    text-align: justify;
}
.skills-container .inner-skills  .content .content-item .icon{
    height: 100px;
}
.skills-container .inner-skills  .content .content-item .icon img{
    width: 70px;
    user-select: none;
}
.skills-container .inner-skills  .content .content-item .date span{
    color: #595959;
    padding: 10px 20px;
    border: 1px solid #ccc;
}
.skills-container .inner-skills  .content .content-item .inner-content-item .ratings{
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 5px;
    position: absolute;
    background-color: #efefef;
}
.skills-container .inner-skills  .content .content-item .inner-content-item .ratings .bar{
    width: 0%;
    height: 5px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    transition: width 2s ease;
    background-color: #20c997;
}
.skills-container .inner-skills .education-skills{
    margin: 50px 0px;
}
.skills-container .inner-skills .education-skills .content{
    margin: 20px 0px;
    padding: 10px 0px;
}
.skills-container .inner-skills .education-skills .date{
    color: #555;
    margin: 10px 0px;
    padding: 10px 30px;;
    display: inline-block;
    border: 1px solid #dcdcdc;
}
.skills-container .inner-skills .education-skills .title{
    margin: 10px 0px;
}
.skills-container .inner-skills .education-skills .title h3{
    color: #333;
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
}
.skills-container .inner-skills .education-skills .body{
    font-size: 18px;
    text-align: justify;
}
/* ************* dark mode ************ */
.portfolio-container.dark .skills-container .inner-skills .title-header h3{
   color: #FDB813;
}
.portfolio-container.dark .skills-container .inner-skills .title-header .title h1{
    color: #fff;
}
.portfolio-container.dark .skills-container .inner-skills  .content .content-item{
    background-color: #151515;
    border-top: 1px solid #202020;
    border-right: 1px solid #202020;
    border-left: 1px solid #202020;
}
.portfolio-container.dark .skills-container .inner-skills  .content .content-item .title  h3{
    color: #fff;
}
.portfolio-container.dark .skills-container .inner-skills  .content .content-item .description{
    color: #7b7b7b;
}
.portfolio-container.dark .skills-container .inner-skills  .content .content-item .date span{
    border: 1px solid #202020;
    background-color: #151515;
}
.portfolio-container.dark .skills-container .inner-skills  .content .content-item .inner-content-item{
    border: 1px solid transparent;
}
.portfolio-container.dark .skills-container .inner-skills  .content .content-item .inner-content-item .ratings{
    background-color: #202020;
}
.portfolio-container.dark .skills-container .inner-skills .education-skills .date{
    color: #FDB813;
    border: 1px solid #FDB813;
    background-color: #151515;
}
.portfolio-container.dark .skills-container .inner-skills .education-skills .title h3{
    color: #ccc;
}
.portfolio-container.dark .skills-container .inner-skills .education-skills .body{
    color: #7b7b7b;
}
@media only screen and (max-width: 1400px){
    .skills-container .inner-skills{
        width: 90%;
    }
}
@media only screen and (max-width: 567px){
    .skills-container .inner-skills{
        width: 95%;
    }
    .skills-container .inner-skills .title-header .title h1{
        font-size: 35px;
        text-align: center;
    }
    .skills-container .inner-skills  .content .content-item .inner-content-item{
        padding: 0px;
        min-height:inherit;
    }
    .skills-container .inner-skills .education-skills .date{
        font-size: 14px;
        padding: 7px 15px;
    }
    .portfolio-container.dark .skills-container .inner-skills  .content .content-item .date span{
        font-size: 14px;
        border: 1px solid transparent;
    }
    .skills-container .inner-skills .education-skills .title h3{
        font-size: 20px;
    }
    .skills-container .inner-skills .education-skills .body{
        font-size: 14px;
    }
    .skills-container .inner-skills  .content .content-item .icon{
        height: 70px;
    }
    .skills-container .inner-skills  .content .content-item .icon img{
        width: 60px;
        height: 40px;
        object-fit: contain;
    }
    .skills-container .inner-skills  .content .content-item .inner-content-item .icon,
    .skills-container .inner-skills  .content .content-item .inner-content-item .date,
    .skills-container .inner-skills  .content .content-item .inner-content-item .title,
    .skills-container .inner-skills  .content .content-item .inner-content-item .description{
        padding: 10px;
    }
    .skills-container .inner-skills  .content .content-item .inner-content-item .ratings{
        position: relative;
    }
    .skills-container .inner-skills  .content .content-item .inner-content-item .ratings,
    .skills-container .inner-skills  .content .content-item .inner-content-item .ratings .bar{
        height: 5px;
        border-radius: 10px;
    }
    .skills-container .inner-skills  .content .content-item .inner-content-item .title h3{
        font-size: 16px;
    }
}
/* ************** PORTFOLIO SECTION **************** */
.portfolio-content-container{
    padding: 100px 0px;
    background-color: #f8f8f8;
}
.portfolio-content-container .inner-portfolio-content{
    width: 70%;
    margin: 0 auto;
}
.portfolio-content-container .inner-portfolio-content .title-header{
    margin: 20px 0px;
}
.portfolio-content-container .inner-portfolio-content .title-header h3{
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}
.portfolio-content-container .inner-portfolio-content .title-header .title h1{
    font-size: 60px;
    margin: 20px 0px;
    font-weight: bold;
    letter-spacing: 5px;
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item{
    width: 100%;
    margin: 20px 0px;
    position: relative;
    border: 1px solid #ebebeb;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image{
    width: 100%;
    height: 350px;
    cursor: pointer;
    transition: all 0.2s ease;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item.large{
    margin: 50px 0px;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item.large .image{
    height: 100%;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.portfolio-content-container .inner-portfolio-content .show-more{
    display: flex;
    margin: 50px 0px;
    padding: 20px 20px;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
}
.portfolio-content-container .inner-portfolio-content .show-more .link{
    width: 50%;
}
.portfolio-content-container .inner-portfolio-content .show-more .link a{
    color: #333;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}
.portfolio-content-container .inner-portfolio-content .show-more .button .icon{
    color: #333;
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
}
.portfolio-content-container .inner-portfolio-content .show-more .link a:hover,
.portfolio-content-container .inner-portfolio-content .show-more .button .icon:hover{
    color: #FDB813;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom{
    width: 100%;
    height: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    position: absolute;
    overflow: hidden;
    transition: all 0.3s ease;
    background-image: linear-gradient(to top, black, #3333335c);
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item.large:hover .image-bottom{
    height: 200px;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item:hover .image-bottom{
    height: 100px;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul{
    width: 100%;
    padding: 0px 20px;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li.title-header{
    margin: 0px;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li.title-header h3{
    margin: 0px;
    padding: 0px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li{
    width: 100%;
    line-height: 25px;
    position: relative;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li a{
    color: #fff;
    right: -75px;
    font-size: 14px;
    position: relative;
    text-transform: capitalize;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li a:hover{
    color: #FDB813;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li.technology-button{
    text-align: right;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li.technology-button button{
    border: none;
    color: #ccc;
    font-size: 16px;
    background-color: transparent;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li.technology-button button:hover{
    color: #FDB813;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li.technology-button .icon{
    color: #FDB813;
    margin: 0px 5px;
    font-size: 22px;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li a::before{
    top: 5px;
    left: -75px;
    content: " ";
    width: 70px;
    height: 5px;
    position: absolute;
    background-color: #20c997;
}
.portfolio-content-container .inner-portfolio-content .portfolio-content-item .item-count{
    top: 0px;
    right: 0px;
    color: #333;
    padding: 5px 10px;
    position: absolute;
    font-weight: bold;
    background-color: #fdb7139d;
}
.portfolio-content-container .inner-portfolio-content .portfolio-pupup{
    top: -50px;
    left: 0px;
    z-index: 30;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}
.portfolio-content-container .inner-portfolio-content .portfolio-pupup.active{
    top: 0px;
    opacity: 1;
    visibility: visible;
}
.portfolio-content-container .inner-portfolio-content .portfolio-pupup .dark-skin{
    top: 0px;
    left: 0px;
    z-index: 10;
    width: 100%;
    height: 100vh;
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.654);
}
.portfolio-content-container .inner-portfolio-content .portfolio-pupup.active .dark-skin{
    display: block;
}
.portfolio-content-container .inner-portfolio-content .portfolio-pupup .image{
    top: 0px;
    width: 70%;
    height: 70%;
    z-index: 10;
    position: relative;
}
.portfolio-content-container .inner-portfolio-content .portfolio-pupup .image img{
    width: 100%;
    height: 100%;
    cursor: pointer;
    user-select: none;
    object-fit: contain;
}
.portfolio-content-container .inner-portfolio-content .portfolio-pupup .direction{
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0px 10px;
    align-items: center;
    position: absolute;
    justify-content: space-between;
}
.portfolio-content-container .inner-portfolio-content .portfolio-pupup .direction .icon{
    color: #fff;
    cursor: pointer;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #1b1b1bbf;
}
.portfolio-content-container .inner-portfolio-content .portfolio-pupup .direction .icon:hover{
    color: #FDB813;
    background-color: #fdb71333;
}
.portfolio-content-container .technologies-container{
    top: 0px;
    left: 0px;
    z-index: 30;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}
.portfolio-content-container .technologies-container .dark-skin{
    top: 0px;
    left: 0px;
    z-index: 30;
    width: 100%;
    height: 100vh;
    display: block;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.654);
}
.portfolio-content-container .technologies-container .technoloy-inner{
    width: 60%;
    height: 60%;
    z-index: 30;
    padding: 20px;
    position: relative;
    border-radius: 3px;
    background-color: #fff;
}
.portfolio-content-container .technologies-container .technoloy-inner .title-header{
    text-align: center;
    position: relative;
    margin-bottom: 30px;
}
.portfolio-content-container .technologies-container .technoloy-inner .title-header .icon{
    top: -30px;
    right: -10px;
    color: #fff;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    transition: all 0.3s ease;
    background-color: #EB5757;
}
@keyframes btnSpinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(180deg); }
}
.portfolio-content-container .technologies-container .technoloy-inner .title-header .icon:hover{
    animation: btnSpinner 0.3s linear;
}
.portfolio-content-container .technologies-container .technoloy-inner .body{
    height: 80vh;
    overflow-Y: auto;
}
.portfolio-content-container .technologies-container .technoloy-inner .body li{
    color: #555;
    padding: 5px 0px;
}
.portfolio-content-container .technologies-container .technoloy-inner .body li.description{
    margin: 10px 0px;
}
.portfolio-content-container .technologies-container .technoloy-inner .body li span{
    color: #333;
    font-weight: bold;
}
.portfolio-content-container .technologies-container .technoloy-inner .body li .tech{
    margin: 10px 0px;
}
.portfolio-content-container .technologies-container .technoloy-inner .body::-webkit-scrollbar{
    width: 5px;
    background:  transparent;
}
.portfolio-content-container .technologies-container .technoloy-inner .body::-webkit-scrollbar-thumb {
    background:  #e6e6e6;
    border-radius: 10px;
}
.portfolio-content-container .technologies-container .technoloy-inner .body li .tech  div{
    padding: 5px 20px;
    margin: 7px 10px;
    border-radius: 3px;
    display: inline-block;
    background-color: #fdb71328;
    border: 1px solid #FDB813;
}
.portfolio-content-container .technologies-container .technoloy-inner li.button{
    right: 20px;
    bottom: 10px;
    position: absolute;
}
.portfolio-content-container .technologies-container .technoloy-inner li.button button{
    border: 0px;
    color: #333;
    font-size: 16px;
    padding: 5px 30px;
    margin-left: 15px;
    font-weight: bold;
    background-color: #FDB813;
    border: 2px solid #FDB813;
    transition: all 0.3s ease;
}
.portfolio-content-container .technologies-container .technoloy-inner li.button button:hover{
    background-color: #c38902;
}
/* ************* dark mode ************* */
.portfolio-container.dark .portfolio-content-container{
    background-color: rgb(7, 7, 7);
}
.portfolio-container.dark .portfolio-content-container .inner-portfolio-content .title-header h3{
    color: #FDB813;
}
.portfolio-container.dark .portfolio-content-container .inner-portfolio-content .title-header .title h1{
    color: #ccc;
}
.portfolio-container.dark .portfolio-content-container .inner-portfolio-content .show-more{
    border: 1px solid #202020;
    background-color: #151515;
}
.portfolio-container.dark .portfolio-content-container .inner-portfolio-content .show-more .link a{
    color: #FDB813;
}
.portfolio-container.dark .portfolio-content-container .inner-portfolio-content .show-more .button .icon{
    color: #606060;
}
.portfolio-container.dark .portfolio-content-container .inner-portfolio-content .show-more .link a:hover,
.portfolio-container.dark .portfolio-content-container .inner-portfolio-content .show-more .button .icon:hover{
    color: #FDB813;
}
.portfolio-container.dark  .portfolio-content-container .technologies-container .technoloy-inner{
    border: 1px solid #202020;
    background-color: #151515;
}
.portfolio-container.dark .portfolio-content-container .technologies-container .technoloy-inner .body li span{
    color: #969696;
}
.portfolio-container.dark .portfolio-content-container .technologies-container .technoloy-inner .body li{
    color: #8a8a8a;
}
.portfolio-container.dark .portfolio-content-container .technologies-container .technoloy-inner .body li .tech  div{
    background-color: #131312;
    border: 1px solid #292929;
}
.portfolio-container.dark .portfolio-content-container .inner-portfolio-content .portfolio-content-item{
    border: 1px solid #202020;
}
@media only screen and (max-width: 1400px){
    .portfolio-content-container .inner-portfolio-content{
        width: 90%;
    }
    .portfolio-content-container .inner-portfolio-content .portfolio-pupup .image{
        width: 80%;
        height: 80%;
    }
    .portfolio-content-container .technologies-container .technoloy-inner{
        width: 70%;
        height: 80vh;
    }
    .portfolio-content-container .technologies-container .technoloy-inner .body{
        height: 55vh;
    }
}
@media only screen and (max-width: 1200px){
    .portfolio-content-container .inner-portfolio-content .portfolio-pupup .image{
        width: 80%;
        height: 70%;
    }
    .portfolio-content-container .technologies-container .technoloy-inner{
        width: 80%;
    }
    .portfolio-content-container .technologies-container .technoloy-inner .body li .tech{
        height: 25vh;
    }
    .portfolio-content-container .technologies-container .technoloy-inner{
        height: 80%;
    }
}
@media only screen and (max-width: 992px){
    .portfolio-content-container .inner-portfolio-content .show-more .button{
        display: none;
    }
    .portfolio-content-container .inner-portfolio-content .portfolio-content-item .image{
        width: 100%;
        height: 100%;
    }
    .portfolio-content-container .inner-portfolio-content .portfolio-content-item .image img{
        object-fit: static;
    }
    .portfolio-content-container .inner-portfolio-content .portfolio-pupup .image{
        width: 90%;
        height: 70%;
    }
    .portfolio-content-container .technologies-container .technoloy-inner{
        width: 90%;
        height: 80%;
    }
}

@media only screen and (max-width: 767px){
    .portfolio-content-container .inner-portfolio-content .portfolio-content-item.large .image-bottom{
        height: 100px;
    }
    .portfolio-content-container .inner-portfolio-content .title-header{
        text-align: center;
    }
    .portfolio-content-container .inner-portfolio-content .title-header .title h1{
        font-size: 40px;
        text-align: center;
    }
    .portfolio-content-container .inner-portfolio-content .portfolio-pupup .image{
        width: 95%;
        height: 35%;
    }
    .portfolio-content-container .inner-portfolio-content .portfolio-pupup .image img{
       object-fit: contain;
    }
    .portfolio-content-container .technologies-container .technoloy-inner{
        width: 95%;
    }
}
@media only screen and (max-width: 567px){
    .portfolio-content-container .inner-portfolio-content{
        width: 95%;
    }
    .portfolio-content-container .inner-portfolio-content .title-header .title h1{
        font-size: 35px;
    }
    .portfolio-content-container .inner-portfolio-content .show-more .link a{
        font-size: 14px;
    }
    .portfolio-content-container .inner-portfolio-content .portfolio-content-item .image-bottom ul li.technology-button button{
        font-size: 14px;
    }
}
@media only screen and (max-width: 398px){
    
}
/* *************** TESTIMONIAL SECTION ************* */
.testimonial-container{
    padding: 100px 0px;
}
.testimonial-container .inner-testimonial{
    width: 70%;
    margin: 0 auto;
}
.testimonial-container .inner-testimonial .title-header h3{
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}
.testimonial-container .inner-testimonial .title-header .title h1{
    font-size: 60px;
    margin: 20px 0px;
    font-weight: bold;
    letter-spacing: 5px;
    font-family: impact;
     /* font-family: 'Papyrus', 'Curlz MT', 'Comic Sans MS', fantasy; */
}
.testimonial-container .inner-testimonial .testimonial-slider-frame{
    margin: 50px 0px;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item{
    z-index: 5;
    height: 350px;
    position: relative;
    margin: 50px 10px;
    padding: 10px 10px;
    border: 2px solid #ededed;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.image{
    height: 1px;
    text-align: center;
    position: relative;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.image img{
    top: -80px;
    z-index: 10;
    width:  100px;
    height: 100px;
    user-select: none;
    position: relative;
    text-align: center;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #ededed;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li{
    margin: 20px 0px;
    user-select: none;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.ratings span.rate{
    color: #555;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li .star{
    color: #ccc;
    font-size: 16px;
    margin-right: 5px;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li .star.active{
    color: #FDB813;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.title-header h3{
    font-size: 20px;
    margin-bottom: 10px;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.title-header h4{
    font-size: 18px;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.title-header .icon{
    color: #555;
    margin-right: 10px;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.description{
    font-size: 14px;
    font-weight: 300;
    padding-right: 70px;
    position: relative;
}
.testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.description .icon{
    top: 0px;
    right: 10px;
    font-size: 60px;
    color: #FDB813;
    position: absolute;
}
.testimonial-container .inner-testimonial .react-multiple-carousel__arrow{
    display: none;
}
/* ************** dark mode ********** */
.portfolio-container.dark .testimonial-container .inner-testimonial h3{
    color: #FDB813;
}
.portfolio-container.dark .testimonial-container .inner-testimonial .title h1{
    color: #fff;
}
.portfolio-container.dark .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item{
    border: 2px solid #202020;
    background-color: #151515;
}
.portfolio-container.dark .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.ratings span.rate{
    color: #ccc;
}
.portfolio-container.dark .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li .star{
    color: #333;
}
.portfolio-container.dark .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li .star.active{
    color: #FDB813;
}
.portfolio-container.dark .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.title-header h3{
    color: #ccc;
}
.portfolio-container.dark .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.title-header h4{
    color: #8d8d8d;
}
.portfolio-container.dark .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.title-header .icon{
    color: #8d8d8d;
}
.portfolio-container.dark .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.description{
    color: #a5a5a5;
}
.portfolio-container.dark .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.image img{
    border: 2px solid #202020;
    background-color: #151515;
}
@media only screen and (max-width: 1400px){
    .testimonial-container .inner-testimonial{
        width: 90%;
    }
    .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item{
        margin: 50px 0px;
    }
}
@media only screen and (max-width: 767px){
    
    .testimonial-container .inner-testimonial .title-header{
        text-align: center;
    }
    .testimonial-container .inner-testimonial .title-header .title h1{
        font-size: 40px;
        text-align: center;
    }
}
@media only screen and (max-width: 567px){
    .testimonial-container .inner-testimonial{
        width: 95%;
    }
    .testimonial-container .inner-testimonial .title-header .title h1{
        font-size: 35px;
    }
    .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.description{
        font-size: 14px;
        padding: 0px;
        text-align: justify;
    }
    .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.description .icon{
        display: none;
    }
    .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.title-header{
        text-align: left;
    }
    .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.title-header h4{
        font-size: 16px;
    }
    .testimonial-container .inner-testimonial .testimonial-slider-frame .testimoni-content-item ul li.title-header h3{
        font-size: 18px;
    }
}

/* *************** FOOTER SECTION ************* */
.footer-container {
    background-color: #f8f8f8;
    border-top: 1px solid #ccc;
}
.footer-container .inner-footer{
    width: 70%;
    margin: 0 auto;
    padding: 100px 0px;
}
.footer-container .inner-footer .content-item{
    display: flex;
    align-items: center;
}
.footer-container .inner-footer .content-item .img{
    width: 100px;
    height: 100px;
    display: flex;
    margin-right: 20px;
    align-items: center;
    border-radius: 50%;
    background-color: #FDB813;
    justify-content: center;
}
.footer-container .inner-footer .content-item .img .icon{
   font-size: 40px;
}
.footer-container .inner-footer .content-item .title{
    margin-bottom: 30px;
}
.footer-container .inner-footer .content-item .title h3{
    font-size: 22px;
    font-weight: bold;
}
.footer-container .inner-footer .content-item .content ul li.item{
    font-weight: 500;
    margin-bottom: 5px;
}
.footer-container .bottom-link .inner-bottom-link{
    width: 70%;
    height: 100px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ccc;
}
.footer-container .bottom-link .inner-bottom-link .right ul{
    display: flex;
    align-items: center;
}
.footer-container .bottom-link .inner-bottom-link .left.mobile{
    display: none;
}
.footer-container .bottom-link .inner-bottom-link .right ul li{
    margin-left: 20px;
}
.footer-container .bottom-link .inner-bottom-link .right ul li a{
    transition: color 0.3s ease;
}
.footer-container .bottom-link .inner-bottom-link .right ul li a:hover{
    color: #FDB813;
}
/* ************** dark mode ********** */
.portfolio-container.dark .footer-container{
    background-color: rgb(7, 7, 7);
    border-top: 1px solid #202020;
}
.portfolio-container.dark .footer-container .inner-footer .content-item .title h3{
    color: #bbbbbb;
}
.portfolio-container.dark .footer-container .bottom-link .inner-bottom-link .left,
.portfolio-container.dark .footer-container .inner-footer .content-item .content ul li.item a,
.portfolio-container.dark .footer-container .inner-footer .content-item .content ul li.item,
.portfolio-container.dark .footer-container .bottom-link .inner-bottom-link .right ul li a{
    color: #8b8b8b;
}
.portfolio-container.dark .footer-container .inner-footer .content-item .img .icon{
    color: #222222;
}
.portfolio-container.dark .footer-container .bottom-link .inner-bottom-link{
    border-top: 1px solid #202020;
}
@media only screen and (max-width: 1400px){
    .footer-container .inner-footer,
    .footer-container .bottom-link .inner-bottom-link{
        width: 90%;
    }
}
@media only screen and (max-width: 1200px){
    .footer-container .inner-footer .content-item .img .icon{
        font-size: 20px;
    }
    .footer-container .inner-footer .content-item .img{
        width: 70px;
        height: 70px;
    }
}
@media only screen and (max-width: 992px){
    .footer-container .inner-footer .content-item{
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 767px){
    .footer-container .inner-footer .content-item{
        align-items: initial;
        margin-bottom: 40px;
    }
    .footer-container .bottom-link .inner-bottom-link .left{
        display: none;
    }
    .footer-container .bottom-link .inner-bottom-link .left.mobile{
        display: block;
    }
    .footer-container .bottom-link .inner-bottom-link .right ul{
        justify-content: center;
    }
    .footer-container .bottom-link .inner-bottom-link{
        display: block;
        height: inherit;
        padding: 25px 0px;
        text-align: center;
    }
}
@media only screen and (max-width: 567px){
    .footer-container .inner-footer .content-item .title h3{
        font-size: 18px;
    }
    .footer-container .inner-footer .content-item .content ul li.item{
        font-size: 14px;
    }
    .footer-container .inner-footer .content-item{
        margin-bottom: 40px;
    }
    .footer-container .bottom-link .inner-bottom-link .left,
    .footer-container .bottom-link .inner-bottom-link .right ul{
        font-size: 14px;
    }
}
@media only screen and (max-width: 398px){
    .footer-container .inner-footer .content-item{
        display: block;
        text-align: center;
    }
    .footer-container .inner-footer .content-item .img{
        width: 70px;
        height: 70px;
        margin: 0  auto;
        margin-bottom: 10px;
    }
}
/* *********** REVIEW SECTION *********** */
.app-review-container{
    top: 0px;
    left: 0px;
    z-index: 30;
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
}
.app-review-container .dark-skin{
    top: 0px;
    left: 0px;
    z-index: 30;
    width: 100%;
    height: 100vh;
    display: block;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.654);
}
.app-review-container .review-body{
    z-index: 30;
    width: 50%;
    min-height: 400px;
    max-height: 80vh;
    overflow-Y: auto;
    position: relative;
    background-color: #fff;
}
.app-review-container .review-body::-webkit-scrollbar{
    width: 0px;
    background: transparent;
}
.app-review-container .review-body::-webkit-scrollbar-thumb {
    background: transparent;
}
.app-review-container .review-body .review-star-rating .title-header{
    padding: 50px 20px 20px 20px;
    text-align: center;
    position: relative;
}
.app-review-container .review-body .review-star-rating .title-header h3{
    color: #333;
    font-size: 23px;
    font-weight: bold;
}
.app-review-container .review-body .review-star-rating .title-header .icon{
    top: 10px;
    right: 10px;
    color: #fff;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    transition: all 0.3s ease;
    background-color: #EB5757;
}
.app-review-container .review-body .review-star-rating .title-header .icon:hover{
    animation: btnSpinner 0.3s linear;
}
.app-review-container .review-body .review-star-rating{
    width: 100%;
    height: 100%;
}
.app-review-container .review-body .review-star-rating .review-star-body p{
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
}
.app-review-container .review-body .review-star-rating .review-star-body .stars{
    text-align: center;
    padding: 40px 0px;
}
.app-review-container .review-body .review-star-rating .review-star-body .stars .form-input-alert{
    width: 100%;
    display: inline-block;
}
.app-review-container .review-body .review-star-rating .review-star-body .stars .star{
    font-size: 40px;
    margin: 0px 5px;
    cursor: pointer;
}
.app-review-container .review-body .review-star-rating .review-star-body .stars .star.active{
    color: #FDB813;
}
.app-review-container .review-body .button{
    width: 100%;
    text-align: center;
    padding: 0px 10px;
}
.app-review-container .review-body .button button{
    width: 300px;
    border: none;
    font-weight: bold;
    padding: 10px 30px;
    margin-top: 10px;
    border: 3px solid #FDB813;
    background-color: #FDB813;
    transition: all 0.3s ease;
}
.app-review-container .review-body .button button:hover{
    background-color: #fdb71370;
    border: 3px solid #FDB813;
}
.app-review-container .review-form-rating{
    padding: 20px 20px;
}
.app-review-container .review-form-rating .title-header{
    padding: 40px 20px 20px 20px;
    text-align: center;
    position: relative;
}
.app-review-container .review-form-rating .title-header h3{
    color: #333;
    font-size: 23px;
    font-weight: bold;
}
.app-review-container .review-image-rating .title-header .icon,
.app-review-container .review-form-rating .title-header .icon{
    top: 10px;
    left: 5px;
    color: #333;
    display: block;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    transition: all 0.3s ease;
}
.app-review-container .review-image-rating .title-header .icon:hover,
.app-review-container .review-form-rating .title-header .icon:hover{
    color: #FDB813;
}
.app-review-container .review-form-rating .form input,
.app-review-container .review-form-rating .form textarea,
.app-review-container .review-form-rating .form select{
    outline: none;
    box-shadow: none;
    color: #555;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 10px;
    border-radius: 0px;
}
.app-review-container .review-form-rating .form input:focus,
.app-review-container .review-form-rating .form textarea:focus{
    outline: none;
    box-shadow: none;
    color: #555;
    border: 1px solid #FDB813;
}
.app-review-container .review-form-rating .form input::placeholder,
.app-review-container .review-form-rating .form textarea::placeholder{
    font-size: 14px;
    color: #bcbcbc;
}
.app-review-container .review-form-rating .form label{
    color: #555;
    font-size: 14px;
}
.app-review-container .review-image-rating .review-image-body{
    width: 100%;
    padding: 40px 0px;
    text-align: center;
}
.app-review-container .review-image-rating .review-image-body .image{
    margin: 0 auto;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9e9e9;
}
.app-review-container .review-image-rating .review-image-body .image img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
.app-review-container .review-image-rating .review-image-body .icon{
    cursor: pointer;
    color: #c2c2c2;
    font-size: 120px;
    border-radius: 50%;
}
.app-review-container .review-image-rating .title-header .icon-upload{
    top: 10px;
    right: 10px;
    color: #fff;
    display: block;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    padding: 10px 10px;
    transition: all 0.3s ease;
    background-color: #FDB813;
    border: 2px solid #FDB813;
}
.app-review-container .review-image-rating .title-header .icon-upload:hover{
    background-color: #fdb71370;
    border: 2px solid #FDB813;
}
.app-review-container .review-image-rating .button{
    text-align: center;
}
.app-review-container .review-image-rating .cropper-frame {
    top: 0px;
    left: 0px;
    z-index: 30;
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body{
    width: 500px;
    height: 500px;
    z-index: 30;
    position: relative;
    background-color: #fff;
}
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body canvas{
    display: none;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body .ReactCrop--fixed-aspect,
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body .ReactCrop__child-wrapper{
    width: 100%;
    height: 100%;
}
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body .frame{
    width: 100%;
    height: 100%;
}
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body .frame img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body .crop-button{
    width: 100%;
}
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body .crop-button button{
    width: 50%;
    border: none;
    padding: 5px;
    font-weight: bold;
    border: 2px solid transparent;
    background-color: #fff;
    transition: all 0.3s ease;
}
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body .crop-button button.cancel:hover{
    color: #333;
    background-color: #fcedca;
}
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body .crop-button button.crop{
    color: #fff;
    background-color: #FDB813;
}
.app-review-container .review-image-rating .cropper-frame .cropper-frame-body .crop-button button.crop:hover{
    color: #fff;
    background-color: #fdb71381;
    border: 2px solid #FDB813;
}
.app-review-container .review-body .success-page{
    display: grid;
    padding: 20px 20px;
    min-height: inherit;
    text-align: center;
}
.app-review-container .review-body .success-page .icon-parent{
    width: 150px;
    height: 150px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    border: 3px solid #ffe8b2;
}
.app-review-container .review-body .success-page .icon-parent .icon{
    font-size: 60px;
    color: #FDB813;
}
.app-review-container .review-body .success-page .text h3{
    color: #333;
    font-size: 40px;
    font-weight: 600;
}
.app-review-container .review-body .success-page .text p{
    width: 80%;
    margin: 0 auto;
}
/* ************** dark mode *************** */
.portfolio-container.dark .app-review-container .review-body{
    background-color: #1b1b1b;
    border: 1px solid #323232;
}
.portfolio-container.dark .app-review-container .review-image-rating .review-image-body .image{
    background-color: #272727;
}
.portfolio-container.dark .app-review-container .review-image-rating .review-image-body .image img{
    background-color: #272727;
}
.portfolio-container.dark .app-review-container .review-image-rating .review-image-body .icon{
    color: #5c5c5c;
}
.portfolio-container.dark .app-review-container .review-body .review-star-rating .title-header h3,
.portfolio-container.dark .app-review-container .review-form-rating .title-header h3{
    color: #c4c4c4;
}
.portfolio-container.dark .app-review-container .review-image-rating .title-header .icon,
.portfolio-container.dark .app-review-container .review-form-rating .title-header .icon,
.portfolio-container.dark .app-review-container .review-image-rating .title-header .icon{
    color: #676767;
}
.portfolio-container.dark .app-review-container .review-image-rating .title-header .icon:hover,
.portfolio-container.dark .app-review-container .review-form-rating .title-header .icon:hover,
.portfolio-container.dark .app-review-container .review-image-rating .title-header .icon:hover{
    color: #FDB813;
}
.portfolio-container.dark .app-review-container .review-form-rating .form input,
.portfolio-container.dark .app-review-container .review-form-rating .form textarea,
.portfolio-container.dark .app-review-container .review-form-rating .form select{
    color: #969696;
    background-color: #202020;
    border: 1px solid #323232;
}
.portfolio-container.dark .app-review-container .review-form-rating .form input:focus,
.portfolio-container.dark .app-review-container .review-form-rating .form textarea:focus{
    color: #969696;
    border: 1px solid #875f01;
}
.portfolio-container.dark .app-review-container .review-form-rating .form input::placeholder,
.portfolio-container.dark .app-review-container .review-form-rating .form textarea::placeholder{
    color: #595959;
}
.portfolio-container.dark .app-review-container .review-form-rating .form .word-count,
.portfolio-container.dark .app-review-container .review-form-rating .form label{
    color: #9b9b9b;
}

.portfolio-container.dark .app-review-container .review-body .review-star-rating .review-star-body p{
    color: #7d7d7d;
}
.portfolio-container.dark .app-review-container .review-body .success-page .text h3{
    color: #9b9b9b;
}
.portfolio-container.dark .app-review-container .review-body .success-page .text p{
    color: #7d7d7d;
}
.portfolio-container.dark .app-review-container .review-body .success-page .icon-parent{
    border: 3px solid #7e5a06;
}
@media only screen and (max-width: 1400px){
    .app-review-container .review-body{
        width: 60%;
    }
}
@media only screen and (max-width: 1200px){
    .app-review-container .review-body{
        width: 70%;
    }
}
@media only screen and (max-width: 992px){
    .app-review-container .review-body{
        width: 80%;
    }
}
@media only screen and (max-width: 767px){
    .app-review-container .review-body{
        width: 90%;
        min-height: 400px;
    }
    .app-review-container .review-body .success-page .text p{
        width: 90%;
    }
    .app-review-container .review-image-rating .cropper-frame .cropper-frame-body{
        width: 500px;
        height: 60vh;
    }
    .app-review-container .review-body .review-star-rating .review-star-body .stars{
        margin: 0px;
    }
    .app-review-container .review-body .review-star-rating .review-star-body .stars .star{
        font-size: 30px;
    }
    .app-review-container .review-body .review-star-rating .review-star-body p{
        width: 90%;
        font-size: 16px;
    }
}
@media only screen and (max-width: 567px){
    .app-review-container .review-form-rating{
        padding: 20px 10px;
    }
    .app-review-container .review-body{
        width: 95%;
    }
    .app-review-container .review-form-rating .button,
    .app-review-container .review-body .button button{
        width: 100%;
    }
    .app-review-container .review-body .success-page{
        padding: 30px 10px;
    }
    .app-review-container .review-body .success-page .button{
        width: 100%;
        padding: 0px;
        margin: 0px;
    }
    .app-review-container .review-form-rating .button{
        width: 100%;
        padding: 0px;
    }
    .app-review-container .review-body .title-header h3,
    .app-review-container .review-body .review-star-rating .title-header h3{
        font-size: 16px;
    }
    .app-review-container .review-body .button{
        width: 100%;
    }
    .app-review-container .review-form-rating .title-header{
        padding: 20px;
    }
    .app-review-container .review-form-rating .title-header h3{
        font-size: 16px;
    }
    .app-review-container .review-form-rating .title-header .icon{
        top: -10px;
    }
    .app-review-container .review-form-rating .form label{
        color: #555;
        font-size: 14px;
    }
    .app-review-container .review-form-rating .form input::placeholder,
    .app-review-container .review-form-rating .form textarea::placeholder{
        font-size: 14px;
    }
    .app-review-container .review-body .success-page .text h3{
        font-size: 25px;
    }
    .app-review-container .review-body .success-page .text p{
        width: 95%;
        font-size: 14px;
    }
    .app-review-container .review-image-rating .cropper-frame .cropper-frame-body{
        width: 100%;
    }
}

@media only screen and (max-width: 398px){
    .app-review-container .review-body .review-star-rating .review-star-body .stars{
        padding: 20px 0px;
    }
}
